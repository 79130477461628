import homePageScreen1 from '@/assets/messageDemo/homePageScreen1.png';
import homePageScreen2 from '@/assets/messageDemo/homePageScreen2.png';
import homePageScreen3 from '@/assets/messageDemo/homePageScreen3.png';
import homePageScreen4 from '@/assets/messageDemo/homePageScreen4.png';
import homePageScreen5 from '@/assets/messageDemo/homePageScreen5.png';
import homePageScreen6 from '@/assets/messageDemo/homePageScreen6.png';
import homePageScreen7 from '@/assets/messageDemo/homePageScreen7.png';
import homePageScreen8 from '@/assets/messageDemo/homePageScreen8.png';
import homePageScreen9 from '@/assets/messageDemo/homePageScreen9.png';
import homePageScreen10 from '@/assets/messageDemo/homePageScreen10.png';
import homePageScreen11 from '@/assets/messageDemo/homePageScreen11.png';
import homePageScreen12 from '@/assets/messageDemo/homePageScreen12.png';
import homePageScreen13 from '@/assets/messageDemo/homePageScreen13.png';
import homePageScreen14 from '@/components/homePage/img/dashIns.png';
import homePageScreen15 from '@/components/homePage/img/dashWhatsapp.png';
export const jsonDataInstall = {
  code: 200,
  message: 'OK',
  data: {
    data: [
      {
        logo_url: homePageScreen12,
        name: 'Duolingo',
        version: '7.1.3',
        size: '147.92 MB',
        install_time: '2023-09-22 13:14',
      },
      {
        logo_url: homePageScreen1,
        name: 'Spotify',
        version: '8.7.4',
        size: '202.49 MB',
        install_time: '2023-09-20 09:29',
      },
      {
        logo_url: homePageScreen5,
        name: 'Pokémon GO',
        version: '5.0.2',
        size: '289.75 MB',
        install_time: '2023-09-19 18:00',
      },
      {
        logo_url: homePageScreen11,
        name: 'Clash of Clans',
        version: '9.2.1',
        size: '215.63 MB',
        install_time: '2023-09-18 16:30',
      },
      {
        logo_url: homePageScreen10,
        name: 'Candy Crush Soga',
        version: '3.5.9',
        size: '76.22 MB',
        install_time: '2023-09-17 13:59',
      },
      {
        logo_url: homePageScreen9,
        name: 'Reddit',
        version: '4.9.7',
        size: '98.76 MB',
        install_time: '2023-09-16 12:29',
      },
      {
        logo_url: homePageScreen13,
        name: 'Pinterest',
        version: '6.4.9',
        size: '128.87 MB',
        install_time: '2023-09-15 10:00',
      },
      {
        logo_url: homePageScreen2,
        name: 'YouTube',
        version: '2016.2.3',
        size: '189.35 MB',
        install_time: '2023-09-14 19:59',
      },
      {
        logo_url: homePageScreen3,
        name: 'Netflix',
        version: '7.6.2',
        size: '324.11 MB',
        install_time: '2023-09-13 18:29',
      },
      {
        logo_url: homePageScreen15,
        name: 'WhatsApp',
        version: '2.7.1',
        size: '67.58 MB',
        install_time: '2023-09-12 16:59',
      },
      {
        logo_url: homePageScreen4,
        name: 'X',
        version: '8.9.5',
        size: '82.19 MB',
        install_time: '2023-09-11 15:45',
      },
      {
        logo_url: homePageScreen8,
        name: 'Snapchat',
        version: '11.3.2',
        size: '189.72 MB',
        install_time: '2023-09-10 14:15',
      },
      {
        logo_url: homePageScreen7,
        name: 'TikTok',
        version: '9.8.0',
        size: '89.46 MB',
        install_time: '2023-09-09 12:45',
      },
      {
        logo_url: homePageScreen6,
        name: 'Facebook',
        version: '12.0.1',
        size: '267.81 MB',
        install_time: '2023-09-08 10:30',
      },
      {
        logo_url: homePageScreen14,
        name: 'Instagram',
        version: '10.5.2',
        size: '156.23 MB',
        install_time: '2023-09-07 09:00',
      },
    ],
    total: 2,
    update_time: 1700116734,
  },
};
